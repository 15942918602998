.Admin_main__Y3GHj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  height: 100vh;
  -webkit-transition-property: -webkit-filter;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86)
}

.Admin_mainModal__jXrUi {
  pointer-events: none;
  -webkit-filter: blur(2px) grayscale(1);
          filter: blur(2px) grayscale(1);
}

.Admin_modalWindow__1A7OQ {
  z-index: 9999;
  position: absolute; 
  background-color: rgb(220,220,220, 0.99);
  border-radius: 6px;
  left: 0; 
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
  width: 85vw;
  height: 82vh;
  -webkit-transition: opacity, -webkit-transform;
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;

  -webkit-transform: scaleX(1);

          transform: scaleX(1);
  opacity: 1;
}

.Admin_modalContent__vaVxv { 
  position: absolute;
  left: 0; 
  right: 0;
  top: 0;
  margin-left: auto; 
  margin-right: auto;
  --height: calc(80vh - 48px);
  --width: calc(var(--height) * (1920/1080));
  width: var(--width);
  height: var(--height);
}

.Admin_modalBar__1rjsm {
  position: absolute;
  height: 32px;
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  box-sizing: border-box;
  bottom: 20px;
}

.Admin_previewImage__2upR3, .Admin_regionControl__2BPo9 {
  width: var(--width);
  height: var(--height);
  border: none;
  margin: 0;
  padding: 0;
}
.Admin_regionControl__2BPo9 {
  top: 8px;
}
.Admin_previewImage__2upR3 {
  background-color: #fff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Admin_modalButton__3OSf0 {
  background-color: #2684FF;
}
.Admin_modalButtonDelete__u88gr {
  background-color: #c03232;
}

.Admin_modalWindowHide__3R7bB {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

@-webkit-keyframes Admin_slidein__3jg9y {
  from {
  }

  to {
  }
}

@keyframes Admin_slidein__3jg9y {
  from {
  }

  to {
  }
}

.Admin_modalExit__3hRUM {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  text-align: center;
  color: white;
  border: none;
  background-color: rgba(255, 38, 38, 0.8);
  font-size: 1em; 
}

.Admin_modalExit__3hRUM:hover {
  cursor: pointer;
}


.Admin_tree__23-lN {
  position: absolute;;
  top: 80px;/*3vh;*/
  left: 0;/*50px;*/
  width: 50%;
  height: calc(100vh - 80px);
}

.Admin_treeBadge__1WKSj {
  display: inline;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: rgb(255, 255, 255)
}

.Admin_content__22EqC {
  position: absolute;
  overflow: auto;
  box-sizing: content-box;
  top: 0px;
  right: calc(-1 * (100vw - (625px - 100px)));
  padding: 50px;
  background-color: rgb(241, 241, 241);
  width: calc(50% - 100px);
  height: 90vh;
  /* transform: translateX(); */
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86)
}

.Admin_exitButton__3cAtu {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 38, 38, 0.8);
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 100%;
}

.Admin_exitButton__3cAtu:hover {
  cursor: pointer;
}

.Admin_selectedContent__3jaPk {
  right: 0px;
}

.Admin_generalSettings__1nRRN {
  top: 50px;
  padding-bottom: 0px;
}

.Admin_divider__1Ycxv {
  width: 100%;
  padding-top: 30px;
  border-bottom: 2px solid darkslategray;
}

.Admin_dividerStandalone__u60Mb {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid darkslategray;
}

.Admin_specificSettings__35uot {
  margin-top: 30px;
}

.Admin_settingsEntry__1U0xt {
  width: 100%;
  min-height: 50px;
}

.Admin_settingsName__DiqZ9 {
  display: inline-flex;
  width: 80px;
  text-align: right;
  justify-content: center;
  align-items: center; 
}

.Admin_settingsValue__1hzhm {
  display: inline-block;
  width: calc(100% - 80px - 40px);
  padding-left: 40px;
}

.Admin_input__3BkzG {
  width: calc(100% - 24px);
  height: 100%;
  display: inline-block;
}

.Admin_slideDelete__1XuE5 {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: white;
  border: none;
  background-color: rgba(255, 38, 38, 0.8);
  font-size: 1em; 
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  position: relative;
  left: 112px;
  top: -10px;
}

.Admin_slideEdit__eXLyf {
  width: 24px;
  height: 24px;
  -webkit-transform: translate(-24px, 24px);
          transform: translate(-24px, 24px);
  border-radius: 100%;
  color: white;
  border: none;
  background-color: rgba(110, 103, 0, 0.8);
  font-size: 1em; 
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  position: relative;
  top: -34px;
  left: -7px;
}

.Admin_slideEdit__eXLyf:hover,
.Admin_slideDelete__1XuE5:hover {
  cursor: pointer;
}

.Admin_addImageButton__1RA8- {
  border: none;
  background: #417505;
  border-radius: 3px;
  margin: 5px;
  border: 1px solid #2e5205;
  padding: 8px 12px 8px;
  font-size: 1em;
  color: #fff;
  width: 160px;
}

.Admin_addImageButton__1RA8-:hover {
  cursor: pointer;
}

input:hover,
input:focus {
  /* border: 2px solid #2684FF;*/
  box-shadow: 0 0 0 2px #2684FF;
}

.Admin_webList__295m5 {

}

.Admin_webEntry__3dHlM {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
  border-radius: 24px;
}

.Admin_faqEntry__1-Are {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  border-radius: 24px; 
}

.Admin_webButton__2MD3s {
  background-color: rgba(255, 38, 38, 0.8);
  color: white;
  border-radius: 8px;
  font-size: 16px;
}

.Admin_webDelete__1Dutw {
  position: relative;
  left: 90%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.Admin_controls__25DUh {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 40vw;
  height: 40px;
  display: flex;
  z-index: 99;
  justify-content: space-evenly;
}

.Admin_controlButton__2jFvQ {
  display: block;
  background-color: #ddd;
  z-index: 99;
  font-size: 1.2vw;
  flex: 1 1;
  height: 100%;
  text-align: center;
}

.Admin_reload__7AZte {
  color: #c03232;
}

.Admin_resourceList__3xRMF {
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 8px;
  overflow: auto;
  height: 28px;
  display: flex;
  font-size: 24px;
  flex-direction: row;
}

.Admin_resourceItem__gL7cB, .Admin_resourceItemSelected__79CKI, .Admin_resourceCancelItem__3qr1L {
  font-size: 18px;
  display: flex;
  margin-right: 10px;
  height: 100%;

  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.Admin_resourceItem__gL7cB {
  background-color: #ddd;
}

.Admin_resourceItemSelected__79CKI {
  background-color: #2684FF;
  color: white;
}

.Admin_resourceCancelItem__3qr1L {
  background-color: #c03232;
  color: white;
}

.Admin_resourceControls__3Zr_A {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 26px;
  margin-right: 48px;
  margin-left: 48px;
}

.Admin_resourceButton__QTcrz, .Admin_resourceButtonDelete__2_9To {
  font-size: 20px;
  border-radius: 10px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Admin_resourceButton__QTcrz {
  background-color: #ddd;
}
.Admin_resourceButtonDelete__2_9To {
  background-color: #db1b1b;
  color: white;
}

.Admin_eventUnselect__2uUQc {
  display: inline-flex;
  margin-left: 24px;
  width: 128px;
  justify-content: center;
  align-items: center;
  padding: 9px 0px 9px;
}

.Admin_uploadingModal__1ti1H {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20vw;
  height: 10vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 1);
}

.Admin_uploadingButton__3B5ev {
  margin-top: 1vh;
  border: 0px;
  width: 128px;

}
.Admin_uploadingInnerDiv__38jyv {
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.Admin_uploading__2Umgd { 
  pointer-events: none;
  -webkit-filter: grayscale(0.25) blur(5px);
          filter: grayscale(0.25) blur(5px);
}

.Admin_hiddenModal__1bExj {
  visibility: hidden;
}
.LinkSource {
  padding: 1rem;
  width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LinkSource * {
  margin: 14px 0px 14px;
}
.LinkSource button {
  width: 6vw;
}

.Modal {
  position: absolute;
  left: 50%;
  top: 45vh;
  min-width: 320px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: modal-in 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s backwards;
          animation: modal-in 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s backwards;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.TooltipEntity {
  cursor: pointer;
}
.TooltipEntity__icon {
  color: #43b1b0;
  margin-right: 0.2em;
  width: 1em;
  height: 1em;
}
.TooltipEntity__text {
  background: #eef8ff;
  border-bottom: 1px dotted #43b1b0;
}

.Tooltip {
  position: absolute;
  padding: 0.6rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: #333;
  color: #fff;
  z-index: 2;
  border-radius: 5px;
}
.Tooltip::before {
  content: "";
  position: absolute;
  border: 15px solid transparent;
}
.Tooltip--top {
  margin-top: 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Tooltip--top::before {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-bottom-color: #333;
}
.Tooltip--left {
  margin-left: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Tooltip--left::before {
  top: 50%;
  right: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-right-color: #333;
}
.Tooltip--top-left {
  margin-top: 25px;
}
.Tooltip--top-left::before {
  bottom: 100%;
  left: 25px;
  border-bottom-color: #333;
}
.Tooltip__link {
  margin-right: 1rem;
  display: inline-block;
}
.Tooltip__button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #808080;
  color: #fff;
  padding: 0.25rem 0.25rem;
  border: 0;
  border-radius: 1px;
  cursor: pointer;
}
.Tooltip__button + .Tooltip__button {
  margin-left: 0.5rem;
}
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.main-container-with-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 500px);
  flex-wrap: wrap;
}

.main-banner {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 500px;
  height: 100vh;
  background: white;
}

#full-programm {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 10;
  display: none;
}

#full-programm.active {
  display: block;
}

#programm-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.widget-feed .widget-feed-header .feed-title {
  font-family: gorditabold, Lato, Helvetica, Helvetica Neue, serif !important;
}

.main-div {
  position: relative;
  top: 40px;
  margin: 0 auto;
}

.main-container h2 {
  font-size: 5rem;
}

.main-container a {
  font-size: 1.4rem;
}



.Button_aonButton__37jvl {
  position: absolute;
  width: 225px;
  height: 173px;
  bottom: 0;
  right: 0px;
}

.Button_cover__gKsry {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vw;
  background-color: rgb(0, 0, 0, 0);
  -webkit-animation: Button_black-out__1rtHD 1s both;
          animation: Button_black-out__1rtHD 1s both;
}

.Button_popup__v4wVj {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 70vh;
  width: 70vw;
  margin: 0 auto;
  box-shadow: 0px 0px 4px 3px rgba(53, 88, 75, 0.8);
  opacity: 0;
  -webkit-animation: Button_appear__1r1k4 1s both 0.4s;
          animation: Button_appear__1r1k4 1s both 0.4s;
}

.Button_popupIframe__3TdCC {
  border: 0px black solid;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes Button_black-out__1rtHD {
	from { background-color: rgb(0, 0, 0, 0); }
	to { background-color: rgb(0, 0, 0, 0.5); }
}

@keyframes Button_black-out__1rtHD {
	from { background-color: rgb(0, 0, 0, 0); }
	to { background-color: rgb(0, 0, 0, 0.5); }
}
@-webkit-keyframes Button_appear__1r1k4 {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes Button_appear__1r1k4 {
	from { opacity: 0; }
	to { opacity: 1; }
}
/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(/static/media/Lato-Light.01711130.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(/static/media/Lato-Regular.dccfa5c4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(/static/media/Lato-Bold.d040fe6a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url(/static/media/source-sans-pro-v12-latin-300.ede18477.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-300.a5002963.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url(/static/media/source-sans-pro-v12-latin-300italic.c179c8f2.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-300italic.f939f20b.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url(/static/media/source-sans-pro-v12-latin-regular.899c8f78.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-regular.5cc3aae6.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url(/static/media/source-sans-pro-v12-latin-italic.dac2fca7.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-italic.86b2389f.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url(/static/media/source-sans-pro-v12-latin-600.c85615b2.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-600.a75563d7.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url(/static/media/source-sans-pro-v12-latin-600italic.ae24ed0c.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-600italic.c13797bd.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url(/static/media/source-sans-pro-v12-latin-700.1a4bcb3e.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-700.b03f2ec2.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url(/static/media/source-sans-pro-v12-latin-700italic.ac7f680e.woff2) format('woff2'), 
       url(/static/media/source-sans-pro-v12-latin-700italic.15776eec.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'gorditaregular';
  src: url(/static/media/gordita-regular-webfont.7501e189.woff2) format('woff2'),
       url(/static/media/gordita-regular-webfont.d8b9ceb7.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'gorditabold';
  src: url(/static/media/gordita-bold-webfont.f753dad4.woff2) format('woff2'),
       url(/static/media/gordita-bold-webfont.51059b01.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

html,body {
  margin: 0;
  padding: 0;
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: default;
}

input,
button,
textarea,
:focus {
  outline: none;
}

body {
  position: relative;
  height: 100vh;
  background-color: #fff;
  font-family: gorditabold, Lato, Helvetica, Helvetica Neue, serif;
  overflow: hidden;
  margin: 0;
}

h1 {
  font-size: 75px;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}

p {
  font-size: 24px;
}

li {
  list-style: none;
}

a {
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

svg text {
  pointer-events: none;
}

.btn {
  padding: 18px 35px 18px;
  background: transparent;
  color: white;
  border-radius: 3px;
  font-family: Lato, sans-serif;
  font-size: 42px;
  text-align: center;
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}

.btn.active {
  background: #2d8eff;
}

.btngroup {
}

.btngroup .btn {
  margin-bottom: 30px;
}

.btngroup .btn:last-child {
  margin-bottom: 0;
}

.submenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.submenu .entries {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.submenu .entries .entry {
  background: #cbcbcc;
  width: 20vw;
  height: 20vw;
  padding: 10px;
  text-align: center;
  margin-right: calc(3vw - 20px);
  margin-bottom: calc(3vw - 20px);
  font-size: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-weight: 600;
}

.submenu .entries .entry:last-child {
  margin-right: 0;
}

.submenutitle {
  position: absolute;
  top: 48px;
  font-weight: 200;
  color: #111;
}

.submenutitle > h1{
  font-size: 90px;
}

.backbtn {
  position: fixed;
  bottom: 25px;
  left: 0px;
  font-weight: bold;
  width: 256px;
  height: 90px;
  z-index: 9999;
}

.fullscreen-image,
.fullscreen-video {
  width: 100%;
  height: 100vh;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.fullscreen-website {
  width: 100vw;
  height: 100vh;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.fullscreen-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.fullscreen-video {
  background-color: #111;
}

.slidetitle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #212121;
  padding: 8px 20px 8px;
  color: #111;
  font-size: 36px;
}

.slideback,
.slidenext {
  position: fixed;
  z-index: 10;
  width: 80px;
  height: 80px;
  bottom: 20px;
}

.slideback {
  left: 120px;
}

.slidenext {
  right: 20px;
}


svg path {
  -webkit-transition: fill .2s ease-in-out;
  transition: fill .2s ease-in-out;
  -webkit-transition: stroke .2s ease-in-out;
  transition: stroke .2s ease-in-out;
}


.rst__toolbarButton button {
  border: none;
  background: #ddd;
  border-radius: 3px;
  margin: 5px;
  border: 1px solid #ccc;
}

.rst__toolbarButton button:hover {
  cursor: pointer;
}

input, textarea {
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 8px 12px 9px;
  font-size: 1em;
}

/* used when nothing is selected */
.tree-normal {

}

/* those two are only used when one node is selected */
.tree-selected {
  box-shadow: 0 0 0 5px #2d8eff;
  border-radius: 1px;
}

.tree-not-selected {
  opacity: 0.5 !important;
  -webkit-filter: grayscale(1);
          filter: grayscale(1)
}



.mainbtn { 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 250px;
  margin-bottom: 20px;
  font-family: gorditabold;
  font-weight: bold;
  font-weight: 900;
  font-size: 48px;
  color: #FFFFFF;
  text-align: left;
  text-shadow: 0 0 10px rgba(0,0,0,0.80);
  opacity: 1;
  overflow: hidden;
  background: #ccc;
}

.mainbtn:nth-child(2),
.mainbtn:nth-child(5),
.mainbtn:nth-child(8) {
  margin-left: 20px;
  margin-right: 20px;
}

.mainbtn iframe {
  overflow: hidden;
}

.windowed-website {
  position: absolute;
  top: 45%;
  left: 50%;
  display: flex;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 75vw;
  height: 75vh;
}

.website-list { 
  position: absolute;
  width: 80vw;
  height: 90px;
  bottom: 25px;
  left: 330px;
  display: flex;
}

.uibtn { 
  display: flex; 
  background-color: #746d69;
  font-size: 40px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
}

.btnweb { 
  display: flex;
  padding-left: 45px;
  padding-right: 45px;
  margin-right: 25px;
  font-weight: bold;
  font-size: 39px;
}

.active.btnweb {
  background-color: white;
  color: #746d69;
}

.picture {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 75vw;
  height: 75vh; 
}

.control-info {
  position: absolute;
  left: 910px;
  width: 100px;
  bottom: 48px;
  text-align: center;
  font-size: 48px;
  background-color: #746d69;
  color: white;
}

.control-left {
  position: absolute;
  width: 60px;
  bottom: 48px;
  left: 840px;
}
.control-right {
  position: absolute;
  width: 60px;
  bottom: 48px;
  left: 1020px;
}

.disabled-btn {
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.7)
}

.switchlang {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 48px;
  padding: 5px 20px 5px;
}


.whole-faq {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 48px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: opacity 1s linear, -webkit-filter 0.6s linear;
  transition: opacity 1s linear, -webkit-filter 0.6s linear;
  transition: filter 0.6s linear, opacity 1s linear;
  transition: filter 0.6s linear, opacity 1s linear, -webkit-filter 0.6s linear;
  opacity: 1;
  -webkit-filter: none;
          filter: none;
  pointer-events: all;

  height: calc(100vh - 100px);
  overflow: scroll;
  padding: 10px;
}

.blocked.whole-faq {
  opacity: 0.9;
  -webkit-filter: blur(6px) grayscale(1);
          filter: blur(6px) grayscale(1);
  pointer-events: none;
}

.faq-title {
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 64px;
  color: #111;
}

.faq-entry {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  background-color: rgb(236, 236, 236);
  margin-bottom: 16px;
  font-size: 24px;
}

.faq-question {
  display: flex;
  z-index: 100;
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(0,0,0,.4);
  border-radius: 4px;
  font-weight: bold;
}

.faq-question p,
.faq-answer p {
  margin: 5px;
}

.faq-question-expand {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 32px;
  font-size: 32px;
  opacity: .75;
}

.faq-answer {
  z-index: 0;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.5s, max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
  transition: opacity 0.5s, max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;

}

.shown.faq-answer {
  display: block;
  max-height: 400px;
  opacity: 1;
  padding: 16px;
}

.hidden.faq-answer {
  max-height: 0px;
  opacity: 0;
  padding-top: 0px;
  padding-right: 0px;
}

.linkLooks {
  display: inline;
  background: none !important;
  color: inherit;
  border: none; 
  padding: 0 !important;
  font: inherit;
  /* border is optional */
  border-bottom: 1px solid #444; 
  cursor: pointer;
}

.modal-page {
  position: absolute;
  -webkit-transition: opacity 0.7s linear 0.3s, -webkit-filter 1s linear;
  transition: opacity 0.7s linear 0.3s, -webkit-filter 1s linear;
  transition: opacity 0.7s linear 0.3s, filter 1s linear;
  transition: opacity 0.7s linear 0.3s, filter 1s linear, -webkit-filter 1s linear;
  opacity: 0;
  -webkit-filter: none;
          filter: none;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
}

.modal-page.shown {
  opacity: 1;
}
.modal-page.hidden {
  pointer-events: none;
}

.modal-page-iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.closeButton {
  position: absolute;
  display: flex;
  top: 80px;
  font-size: 64px;
  right: 190px;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #746d69;

}

.main-title {
  position: absolute;
  bottom: 10px;
  left: 5%;
}

.Draftail-Toolbar {
  background-color: #c0c0c5 !important;
}

iframe {
  overflow: none !important;
  background: #fff;
}

.calendar-title {
  color: white;
  font-size: 96px;
  font-weight: bold;
  position: absolute;
  top: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.calendar-toptext {
  font-size: 32px;
  color: white;
  position: absolute;
  top: 160px;
  max-width: 80vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.calendar-content {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 240px;
  height: 650px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.calendar-bottomtext {
  position: absolute;
  color: white;
  top: calc(100vh - 128px);
  font-size: 42px;
  max-width: 70vw;
  left: 25%;
}

.page-change-enter,.page-change-exit {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 100vw;
  height: 100vh;  
}

.page-change-exit.page-change-exit-active {
  -webkit-animation: fadeOut 0.7s ease-in-out both;
          animation: fadeOut 0.7s ease-in-out both;
}

.page-change-enter {
  z-index: 1;
}

.page-change-enter.page-change-enter-active {
  -webkit-animation: fadeIn 0.7s ease-in-out both;
          animation: fadeIn 0.7s ease-in-out both;
}

@-webkit-keyframes moveFromTop {
	from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
	to { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@keyframes moveFromTop {
	from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
	to { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@-webkit-keyframes moveToBottom {
	from { -webkit-transform: translateY(0%); transform: translateY(0%); }
	to { -webkit-transform: translateY(100%); transform: translateY(100%); }
}

@keyframes moveToBottom {
	from { -webkit-transform: translateY(0%); transform: translateY(0%); }
	to { -webkit-transform: translateY(100%); transform: translateY(100%); }
}

.screensaver-trigger {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
@-webkit-keyframes moveToLeft {
	from { -webkit-transform: translateX(0%); transform: translateX(0%); }
	to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}
@keyframes moveToLeft {
	from { -webkit-transform: translateX(0%); transform: translateX(0%); }
	to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@-webkit-keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); transform: translateX(100%); }
	to { -webkit-transform: translateX(0%); transform: translateX(0%); }
}

@keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); transform: translateX(100%); }
	to { -webkit-transform: translateX(0%); transform: translateX(0%); }
}


@-webkit-keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}


@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}

@-webkit-keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
