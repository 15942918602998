.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.main-container-with-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 500px);
  flex-wrap: wrap;
}

.main-banner {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 500px;
  height: 100vh;
  background: white;
}

#full-programm {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 10;
  display: none;
}

#full-programm.active {
  display: block;
}

#programm-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.widget-feed .widget-feed-header .feed-title {
  font-family: gorditabold, Lato, Helvetica, Helvetica Neue, serif !important;
}

.main-div {
  position: relative;
  top: 40px;
  margin: 0 auto;
}

.main-container h2 {
  font-size: 5rem;
}

.main-container a {
  font-size: 1.4rem;
}
