/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~draft-js/dist/Draft.css";
@import "~draftail/dist/draftail.css";
@import "~react-big-scheduler/lib/css/style.css";
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(./font/Lato-Light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(./font/Lato-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(./font/Lato-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('./font/source-sans-pro-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url('./font/source-sans-pro-v12-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('./font/source-sans-pro-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('./font/source-sans-pro-v12-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('./font/source-sans-pro-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url('./font/source-sans-pro-v12-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('./font/source-sans-pro-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url('./font/source-sans-pro-v12-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./font/source-sans-pro-v12-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'gorditaregular';
  src: url('./font/gordita-regular-webfont.woff2') format('woff2'),
       url('./font/gordita-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'gorditabold';
  src: url('./font/gordita-bold-webfont.woff2') format('woff2'),
       url('./font/gordita-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html,body {
  margin: 0;
  padding: 0;
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: default;
}

input,
button,
textarea,
:focus {
  outline: none;
}

body {
  position: relative;
  height: 100vh;
  background-color: #fff;
  font-family: gorditabold, Lato, Helvetica, Helvetica Neue, serif;
  overflow: hidden;
  margin: 0;
}

h1 {
  font-size: 75px;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}

p {
  font-size: 24px;
}

li {
  list-style: none;
}

a {
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

svg text {
  pointer-events: none;
}

.btn {
  padding: 18px 35px 18px;
  background: transparent;
  color: white;
  border-radius: 3px;
  font-family: Lato, sans-serif;
  font-size: 42px;
  text-align: center;
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}

.btn.active {
  background: #2d8eff;
}

.btngroup {
}

.btngroup .btn {
  margin-bottom: 30px;
}

.btngroup .btn:last-child {
  margin-bottom: 0;
}

.submenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.submenu .entries {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.submenu .entries .entry {
  background: #cbcbcc;
  width: 20vw;
  height: 20vw;
  padding: 10px;
  text-align: center;
  margin-right: calc(3vw - 20px);
  margin-bottom: calc(3vw - 20px);
  font-size: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-weight: 600;
}

.submenu .entries .entry:last-child {
  margin-right: 0;
}

.submenutitle {
  position: absolute;
  top: 48px;
  font-weight: 200;
  color: #111;
}

.submenutitle > h1{
  font-size: 90px;
}

.backbtn {
  position: fixed;
  bottom: 25px;
  left: 0px;
  font-weight: bold;
  width: 256px;
  height: 90px;
  z-index: 9999;
}

.fullscreen-image,
.fullscreen-video {
  width: 100%;
  height: 100vh;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.fullscreen-website {
  width: 100vw;
  height: 100vh;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.fullscreen-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.fullscreen-video {
  background-color: #111;
}

.slidetitle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #212121;
  padding: 8px 20px 8px;
  color: #111;
  font-size: 36px;
}

.slideback,
.slidenext {
  position: fixed;
  z-index: 10;
  width: 80px;
  height: 80px;
  bottom: 20px;
}

.slideback {
  left: 120px;
}

.slidenext {
  right: 20px;
}


svg path {
  -webkit-transition: fill .2s ease-in-out;
  transition: fill .2s ease-in-out;
  -webkit-transition: stroke .2s ease-in-out;
  transition: stroke .2s ease-in-out;
}


.rst__toolbarButton button {
  border: none;
  background: #ddd;
  border-radius: 3px;
  margin: 5px;
  border: 1px solid #ccc;
}

.rst__toolbarButton button:hover {
  cursor: pointer;
}

input, textarea {
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 8px 12px 9px;
  font-size: 1em;
}

/* used when nothing is selected */
.tree-normal {

}

/* those two are only used when one node is selected */
.tree-selected {
  box-shadow: 0 0 0 5px #2d8eff;
  border-radius: 1px;
}

.tree-not-selected {
  opacity: 0.5 !important;
  -webkit-filter: grayscale(1);
          filter: grayscale(1)
}



.mainbtn { 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 250px;
  margin-bottom: 20px;
  font-family: gorditabold;
  font-weight: bold;
  font-weight: 900;
  font-size: 48px;
  color: #FFFFFF;
  text-align: left;
  text-shadow: 0 0 10px rgba(0,0,0,0.80);
  opacity: 1;
  overflow: hidden;
  background: #ccc;
}

.mainbtn:nth-child(2),
.mainbtn:nth-child(5),
.mainbtn:nth-child(8) {
  margin-left: 20px;
  margin-right: 20px;
}

.mainbtn iframe {
  overflow: hidden;
}

.windowed-website {
  position: absolute;
  top: 45%;
  left: 50%;
  display: flex;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 75vw;
  height: 75vh;
}

.website-list { 
  position: absolute;
  width: 80vw;
  height: 90px;
  bottom: 25px;
  left: 330px;
  display: flex;
}

.uibtn { 
  display: flex; 
  background-color: #746d69;
  font-size: 40px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
}

.btnweb { 
  display: flex;
  padding-left: 45px;
  padding-right: 45px;
  margin-right: 25px;
  font-weight: bold;
  font-size: 39px;
}

.active.btnweb {
  background-color: white;
  color: #746d69;
}

.picture {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 75vw;
  height: 75vh; 
}

.control-info {
  position: absolute;
  left: 910px;
  width: 100px;
  bottom: 48px;
  text-align: center;
  font-size: 48px;
  background-color: #746d69;
  color: white;
}

.control-left {
  position: absolute;
  width: 60px;
  bottom: 48px;
  left: 840px;
}
.control-right {
  position: absolute;
  width: 60px;
  bottom: 48px;
  left: 1020px;
}

.disabled-btn {
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.7)
}

.switchlang {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 48px;
  padding: 5px 20px 5px;
}


.whole-faq {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 48px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: opacity 1s linear, -webkit-filter 0.6s linear;
  transition: opacity 1s linear, -webkit-filter 0.6s linear;
  transition: filter 0.6s linear, opacity 1s linear;
  transition: filter 0.6s linear, opacity 1s linear, -webkit-filter 0.6s linear;
  opacity: 1;
  -webkit-filter: none;
          filter: none;
  pointer-events: all;

  height: calc(100vh - 100px);
  overflow: scroll;
  padding: 10px;
}

.blocked.whole-faq {
  opacity: 0.9;
  -webkit-filter: blur(6px) grayscale(1);
          filter: blur(6px) grayscale(1);
  pointer-events: none;
}

.faq-title {
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 64px;
  color: #111;
}

.faq-entry {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  background-color: rgb(236, 236, 236);
  margin-bottom: 16px;
  font-size: 24px;
}

.faq-question {
  display: flex;
  z-index: 100;
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(0,0,0,.4);
  border-radius: 4px;
  font-weight: bold;
}

.faq-question p,
.faq-answer p {
  margin: 5px;
}

.faq-question-expand {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 32px;
  font-size: 32px;
  opacity: .75;
}

.faq-answer {
  z-index: 0;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.5s, max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
  transition: opacity 0.5s, max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;

}

.shown.faq-answer {
  display: block;
  max-height: 400px;
  opacity: 1;
  padding: 16px;
}

.hidden.faq-answer {
  max-height: 0px;
  opacity: 0;
  padding-top: 0px;
  padding-right: 0px;
}

.linkLooks {
  display: inline;
  background: none !important;
  color: inherit;
  border: none; 
  padding: 0 !important;
  font: inherit;
  /* border is optional */
  border-bottom: 1px solid #444; 
  cursor: pointer;
}

.modal-page {
  position: absolute;
  -webkit-transition: opacity 0.7s linear 0.3s, -webkit-filter 1s linear;
  transition: opacity 0.7s linear 0.3s, -webkit-filter 1s linear;
  transition: opacity 0.7s linear 0.3s, filter 1s linear;
  transition: opacity 0.7s linear 0.3s, filter 1s linear, -webkit-filter 1s linear;
  opacity: 0;
  -webkit-filter: none;
          filter: none;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
}

.modal-page.shown {
  opacity: 1;
}
.modal-page.hidden {
  pointer-events: none;
}

.modal-page-iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.closeButton {
  position: absolute;
  display: flex;
  top: 80px;
  font-size: 64px;
  right: 190px;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #746d69;

}

.main-title {
  position: absolute;
  bottom: 10px;
  left: 5%;
}

.Draftail-Toolbar {
  background-color: #c0c0c5 !important;
}

iframe {
  overflow: none !important;
  background: #fff;
}

.calendar-title {
  color: white;
  font-size: 96px;
  font-weight: bold;
  position: absolute;
  top: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.calendar-toptext {
  font-size: 32px;
  color: white;
  position: absolute;
  top: 160px;
  max-width: 80vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.calendar-content {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 240px;
  height: 650px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.calendar-bottomtext {
  position: absolute;
  color: white;
  top: calc(100vh - 128px);
  font-size: 42px;
  max-width: 70vw;
  left: 25%;
}

.page-change-enter,.page-change-exit {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 100vw;
  height: 100vh;  
}

.page-change-exit.page-change-exit-active {
  -webkit-animation: fadeOut 0.7s ease-in-out both;
          animation: fadeOut 0.7s ease-in-out both;
}

.page-change-enter {
  z-index: 1;
}

.page-change-enter.page-change-enter-active {
  -webkit-animation: fadeIn 0.7s ease-in-out both;
          animation: fadeIn 0.7s ease-in-out both;
}

@-webkit-keyframes moveFromTop {
	from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
	to { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@keyframes moveFromTop {
	from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
	to { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@-webkit-keyframes moveToBottom {
	from { -webkit-transform: translateY(0%); transform: translateY(0%); }
	to { -webkit-transform: translateY(100%); transform: translateY(100%); }
}

@keyframes moveToBottom {
	from { -webkit-transform: translateY(0%); transform: translateY(0%); }
	to { -webkit-transform: translateY(100%); transform: translateY(100%); }
}

.screensaver-trigger {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
@-webkit-keyframes moveToLeft {
	from { -webkit-transform: translateX(0%); transform: translateX(0%); }
	to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}
@keyframes moveToLeft {
	from { -webkit-transform: translateX(0%); transform: translateX(0%); }
	to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@-webkit-keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); transform: translateX(100%); }
	to { -webkit-transform: translateX(0%); transform: translateX(0%); }
}

@keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); transform: translateX(100%); }
	to { -webkit-transform: translateX(0%); transform: translateX(0%); }
}


@-webkit-keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}


@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}

@-webkit-keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}