.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  height: 100vh;
  -webkit-transition-property: -webkit-filter;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86)
}

.mainModal {
  pointer-events: none;
  -webkit-filter: blur(2px) grayscale(1);
          filter: blur(2px) grayscale(1);
}

.modalWindow {
  z-index: 9999;
  position: absolute; 
  background-color: rgb(220,220,220, 0.99);
  border-radius: 6px;
  left: 0; 
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
  width: 85vw;
  height: 82vh;
  -webkit-transition: opacity, -webkit-transform;
  transition: opacity, -webkit-transform;
  transition: transform, opacity;
  transition: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 250ms;
          transition-duration: 250ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;

  -webkit-transform: scaleX(1);

          transform: scaleX(1);
  opacity: 1;
}

.modalContent { 
  position: absolute;
  left: 0; 
  right: 0;
  top: 0;
  margin-left: auto; 
  margin-right: auto;
  --height: calc(80vh - 48px);
  --width: calc(var(--height) * (1920/1080));
  width: var(--width);
  height: var(--height);
}

.modalBar {
  position: absolute;
  height: 32px;
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  box-sizing: border-box;
  bottom: 20px;
}

.previewImage, .regionControl {
  width: var(--width);
  height: var(--height);
  border: none;
  margin: 0;
  padding: 0;
}
.regionControl {
  top: 8px;
}
.previewImage {
  background-color: #fff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.modalButton {
  background-color: #2684FF;
}
.modalButtonDelete {
  background-color: #c03232;
}

.modalWindowHide {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

@-webkit-keyframes slidein {
  from {
  }

  to {
  }
}

@keyframes slidein {
  from {
  }

  to {
  }
}

.modalExit {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  text-align: center;
  color: white;
  border: none;
  background-color: rgba(255, 38, 38, 0.8);
  font-size: 1em; 
}

.modalExit:hover {
  cursor: pointer;
}


.tree {
  position: absolute;;
  top: 80px;/*3vh;*/
  left: 0;/*50px;*/
  width: 50%;
  height: calc(100vh - 80px);
}

.treeBadge {
  display: inline;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: rgb(255, 255, 255)
}

.content {
  position: absolute;
  overflow: auto;
  box-sizing: content-box;
  top: 0px;
  right: calc(-1 * (100vw - (625px - 100px)));
  padding: 50px;
  background-color: rgb(241, 241, 241);
  width: calc(50% - 100px);
  height: 90vh;
  /* transform: translateX(); */
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86)
}

.exitButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 38, 38, 0.8);
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 100%;
}

.exitButton:hover {
  cursor: pointer;
}

.selectedContent {
  right: 0px;
}

.generalSettings {
  top: 50px;
  padding-bottom: 0px;
}

.divider {
  width: 100%;
  padding-top: 30px;
  border-bottom: 2px solid darkslategray;
}

.dividerStandalone {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid darkslategray;
}

.specificSettings {
  margin-top: 30px;
}

.settingsEntry {
  width: 100%;
  min-height: 50px;
}

.settingsName {
  display: inline-flex;
  width: 80px;
  text-align: right;
  justify-content: center;
  align-items: center; 
}

.settingsValue {
  display: inline-block;
  width: calc(100% - 80px - 40px);
  padding-left: 40px;
}

.input {
  width: calc(100% - 24px);
  height: 100%;
  display: inline-block;
}

.slideDelete {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: white;
  border: none;
  background-color: rgba(255, 38, 38, 0.8);
  font-size: 1em; 
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  position: relative;
  left: 112px;
  top: -10px;
}

.slideEdit {
  width: 24px;
  height: 24px;
  -webkit-transform: translate(-24px, 24px);
          transform: translate(-24px, 24px);
  border-radius: 100%;
  color: white;
  border: none;
  background-color: rgba(110, 103, 0, 0.8);
  font-size: 1em; 
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
  position: relative;
  top: -34px;
  left: -7px;
}

.slideEdit:hover,
.slideDelete:hover {
  cursor: pointer;
}

.addImageButton {
  border: none;
  background: #417505;
  border-radius: 3px;
  margin: 5px;
  border: 1px solid #2e5205;
  padding: 8px 12px 8px;
  font-size: 1em;
  color: #fff;
  width: 160px;
}

.addImageButton:hover {
  cursor: pointer;
}

input:hover,
input:focus {
  /* border: 2px solid #2684FF;*/
  box-shadow: 0 0 0 2px #2684FF;
}

.webList {

}

.webEntry {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
  border-radius: 24px;
}

.faqEntry {
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  border-radius: 24px; 
}

.webButton {
  background-color: rgba(255, 38, 38, 0.8);
  color: white;
  border-radius: 8px;
  font-size: 16px;
}

.webDelete {
  position: relative;
  left: 90%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.controls {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 40vw;
  height: 40px;
  display: flex;
  z-index: 99;
  justify-content: space-evenly;
}

.controlButton {
  display: block;
  background-color: #ddd;
  z-index: 99;
  font-size: 1.2vw;
  flex: 1 1;
  height: 100%;
  text-align: center;
}

.reload {
  color: #c03232;
}

.resourceList {
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 8px;
  overflow: auto;
  height: 28px;
  display: flex;
  font-size: 24px;
  flex-direction: row;
}

.resourceItem, .resourceItemSelected, .resourceCancelItem {
  font-size: 18px;
  display: flex;
  margin-right: 10px;
  height: 100%;

  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.resourceItem {
  background-color: #ddd;
}

.resourceItemSelected {
  background-color: #2684FF;
  color: white;
}

.resourceCancelItem {
  background-color: #c03232;
  color: white;
}

.resourceControls {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 26px;
  margin-right: 48px;
  margin-left: 48px;
}

.resourceButton, .resourceButtonDelete {
  font-size: 20px;
  border-radius: 10px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resourceButton {
  background-color: #ddd;
}
.resourceButtonDelete {
  background-color: #db1b1b;
  color: white;
}

.eventUnselect {
  display: inline-flex;
  margin-left: 24px;
  width: 128px;
  justify-content: center;
  align-items: center;
  padding: 9px 0px 9px;
}

.uploadingModal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20vw;
  height: 10vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 1);
}

.uploadingButton {
  margin-top: 1vh;
  border: 0px;
  width: 128px;

}
.uploadingInnerDiv {
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.uploading { 
  pointer-events: none;
  -webkit-filter: grayscale(0.25) blur(5px);
          filter: grayscale(0.25) blur(5px);
}

.hiddenModal {
  visibility: hidden;
}