

.aonButton {
  position: absolute;
  width: 225px;
  height: 173px;
  bottom: 0;
  right: 0px;
}

.cover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vw;
  background-color: rgb(0, 0, 0, 0);
  -webkit-animation: black-out 1s both;
          animation: black-out 1s both;
}

.popup {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 70vh;
  width: 70vw;
  margin: 0 auto;
  box-shadow: 0px 0px 4px 3px rgba(53, 88, 75, 0.8);
  opacity: 0;
  -webkit-animation: appear 1s both 0.4s;
          animation: appear 1s both 0.4s;
}

.popupIframe {
  border: 0px black solid;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes black-out {
	from { background-color: rgb(0, 0, 0, 0); }
	to { background-color: rgb(0, 0, 0, 0.5); }
}

@keyframes black-out {
	from { background-color: rgb(0, 0, 0, 0); }
	to { background-color: rgb(0, 0, 0, 0.5); }
}
@-webkit-keyframes appear {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes appear {
	from { opacity: 0; }
	to { opacity: 1; }
}